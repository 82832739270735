@media (min-width: 576px) {
    header {
        position: absolute;
    }

    #resumebody{
        background: linear-gradient(to bottom left, white 5%, #E9B9FF 5% 8%, 
        #CB8BE5 8% 14%, #8537A5 14% 24%, 
        #690990 24% 90%, #222222 90% 100%) 
        no-repeat center center fixed;
    }

    #resumemain {
        display: grid;
        font-family: 'Montserrat', sans-serif;
        margin-left: auto;
        margin-right: auto;
        z-index: 1;
        grid-template-rows: 100vh 80%;
    }

    .top-content {
        height: 100vh;
        display: grid;
        width: 100%;
        grid-template-columns: 1fr min(80vw, 125ch) 1fr;
        grid-row: 1/2;
    }

    .main-info {
        background-color: rgba(255,255,255,0.2);
        backdrop-filter: blur(10px);
        max-height: 80vh;
        max-width: 10vw;
        grid-column: 2/2;
        top: 15vh;
        position: relative;
        border: white 2px solid;
        border-radius: 30px 0px 30px 0px;
        width: 100%;
        min-width: 51ch;
        max-width: 150ch;
        height: min(70vh, 50vw);
        grid-template-columns: 50% 50%;
        /* display: grid; */
    }

    #professional-img {
        position: relative;
        height: 100%;
        float: left;
        margin-right: 1.2em;
        border: 1px;
        border-radius: 30px 0px 0px 0px;
        grid-column: 1/2;
        display: grid;
    }

    .name {
        font-size: 4em;
        margin-bottom: 0;
        margin-top: 0;
    }

    .social-info {
        text-align: right;
        margin-right: 6.5%;
        grid-column: 2/3;
        display: grid;
    }
    
    .socials{
        list-style-type: none;
    }

    .socials li:not(.location) {
        margin-top: 2.5%;
        margin-bottom: 2.5%;
        border-top: #222222 3px solid;
    }

    .socials .location{
        font-size: 3.5em;
        margin-top: 0%;
    }

    main .socials span:not(li.location span){
        font-size: 1.65em;
        font-style: italic;
    }

    .socials a,.socials a:link,.socials a:visited {
        font-size: 1.4em;
        transition: all .1s ease;
        text-decoration: none;
        color: #E9B9FF;
    }

    .socials a:hover{
        transition: all .1s ease;
        color: white;
    }

    .bottom-content{
        background-color: lightgray;
        border-top: gray 5ch solid;
        grid-row: 2/3;
        /* height: 100vh; */
        grid-template-columns: 50% 50%;
        display: grid;
        padding: 1% 13%;
    }

    .left-resume {
        grid-column: 1/2;
    }

    .right-resume {
        grid-column: 2/3;
    }
    
    .right-resume, .left-resume {
        height: auto;
        padding: 5%;
        margin: 2%;
        background-color: white;
    }

    .right-resume h1, .left-resume h1{
        width: 100%;
        border: #CB8BE5 2px solid;
    }
    
    .key-header:not(ul) {
        font-size: 1em;
    }

    .key-header ul {
        font-size: 1em;
    }
    
    .mobile {
        display: none;
    }

    #bottom-header h1{
        margin: 3%;
        padding: 1%;
        font-size: 175%
    }
}

@media (max-width: 576px) {
    body {
        background: linear-gradient(white 5%,#E9B9FF 5% 40%, 
        #CB8BE5 40% 62%, #8537A5 62% 75%, 
        #690990 75% 98%, #222222 98% 100%) no-repeat center center fixed;
    }

    .mobile {
        display: block;
        margin-left: 15%;
        margin-top: -6%;
        padding: none;
    }

    .top-content, .bottom-content{
        display: none;
    }
}