#has-border-darker {
    background: #999999;
    color: #690990;
}

#has-border-dark {
    background: #888888;
    color: #8537A5;
}

#has-border-lighter {
    background: #777777;
    color: #CB8BE5;
}

#has-border-light {
    background: #666666;
    color: #E9B9FF;
}

/* Media for a PC */
@media (min-width: 576px) {
    #navHeader {
        width: 100%;
        height: 12vh;
        display: grid;
        z-index: 1;
    }

    #navHeader span {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }

    .navButton {
        transition: 0.25s ease;
        height: 50%;
        width: 100%;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 1.5vw;
        text-align: center;
        line-height: 1;
        display: flex;
        justify-content: center;
        flex-direction: column;
    }

    .navButton:hover {
        transition: 0.25s ease;
        height: 80%;
        padding-top: 10%;
    }

    #has-border-darker {
        grid-column: 7/8;
    }

    nav {
        /* display: flex;
        justify-content: flex-end; */
        width: 100%;
        padding-right: 0%;
        display: grid;
        grid-template-columns: repeat(10, 10%);
    }

    #logoAgain,
    #logoAgain:visited {
        color: #dddddd;
    }

    #logoAgain:hover {
        /* color:#444444; */
        animation: spin 1s ease-in-out;
        animation-iteration-count: infinite;
        /* scale: 150% */
    }

    nav a:link {
        text-decoration: none;
    }

    nav a:visited {
        text-decoration: none;
    }

    .tapBox {
        display: none;
    }

    footer {
        z-index: 1;
        bottom: 0;
        width: 100%;
        height: 5%;
        position: absolute;
        background-color: #444444;
        text-align: left;
    }

    .footer-socials {
        color: #CB8BE5;
        font-size: 1.8em;
        padding: .1%;
    }

    .foot-social {
        margin: 2px;
        /* border-right: 2px solid black; */
        padding-right: 1%;
    }

    .foot-social>a {
        color: #E9B9FF;
        text-decoration: none;
        margin-top: 0;
        margin-bottom: 0;
    }

    .foot-social>a:visited {
        color: #E9B9FF;
    }

    .foot-social>a:hover {
        color: #dddddd
    }

    #foot-discord-social {
        border-right: 0px
    }
}

/* Media for a Phone */
@media (max-width: 576px) {
    body {
        margin: 0;
    }

    header {
        position: absolute;
        height: 100vh;
        margin: 0;
        padding: 0;
        z-index: 10;
    }

    .navButton {
        width: 100%;
        font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
        font-size: 10vw;
        text-align: center;
        margin: 0;
        padding: 0;
        display: block;
    }

    #logo,
    #logo img {
        background-color: #AAAAAA;
        width: 290px;
        animation: spin ease-in-out 1s;
        animation-iteration-count: infinite;
    }

    nav img {
        width: 288px;
    }

    nav {
        width: 290px;
        transform: translateX(-240px);
        z-index: 10;
        position: absolute;
        animation: nav-close 280ms ease;
        top: 0;
        background-color: #AAAAAA;
    }

    nav a:link {
        text-decoration: none;
    }

    nav a:visited {
        text-decoration: none;
    }

    #tapBox {
        display: block;
        position: absolute;
        height: 100%;
        width: 290px;
        transform: translateX(-240px);
        z-index: 100;
        animation: nav-close 280ms ease;
        top: 10;
        background-color: rgba(1, 1, 1, 0);
    }

    #graybox {
        display: block;
        position: absolute;
        height: 100%;
        width: 290px;
        transform: translateX(-240px);
        background-color: #444444;
        z-index: 1;
        animation: nav-close 280ms ease;
        top: 10;
    }

    #graybox.grayboxOpen {
        display: none;
    }

    #tapBox.tapBoxOpen {
        width: 100%;
        top: 0;
        animation: nav-open 280ms ease;
        transform: translateX(0);
        z-index: 1;
        background-color: #444444;
    }

    nav.open {
        height: auto;
        margin: 0;
        padding: 0;
        position: sticky;
        display: block;
        z-index: 100;
        animation: nav-open 280ms ease;
        width: 100%;
        transform: translateX(0);
    }

    footer {
        display: none;
    }
}

@keyframes nav-open {
    0% {
        transform: translateX(-240px);
    }

    50% {}

    100% {
        transform: translateX(0);
    }
}

@keyframes nav-close {
    0% {
        transform: translateX(0);
    }

    50% {}

    100% {
        transform: translateX(-240px);
    }
}

@keyframes spin {
    0% {
        /* transform: translateY(2px); */
        color: #dddddd;
    }

    25% {
        color: #CB8BE5
    }

    50% {
        /* transform: translateY(-2px); */
        color: #690990;

    }

    75% {
        color: #cb8be5
    }

    100% {
        /* transform: translateY(2px); */
        color: #dddddd;
    }
}