/* Media for a PC */
@media (min-width: 576px) {
    #contact-body {
        background: linear-gradient(to bottom left, white 8%, #E9B9FF 8% 58%, 
        #CB8BE5 58% 69%, #8537A5 69% 82%, 
        #690990 82% 91%, #222222 91% 100%) 
        no-repeat center center fixed;
    }

    #contact-main {
        text-align: center;
    }

    #contact-main h1{
        font-weight: 500;
        text-decoration: underline;
        font-size: 4em;
    }

    .contact-socials {
        list-style-type: none;
        font-size: 2em;
        position: relative;
        padding-inline-start: 0px;
    }

    .contact-socials li {
        position: relative;
        width: auto;
        z-index: 0;
    }

    .sub-social {
        height: auto;
    }

    .sub-social img {
        transition: all 0.05s linear;
        height: 7vh;
        border: 1px;
        border-radius: 15px;
        z-index: 2;
        position: relative;
    }

    .sub-social img:hover {
        transform: scale(1.25);
        transform-origin: top;
    }

    .contact-frosted {
        background-color: rgba(255, 255, 255, .25);
        backdrop-filter: blur(25px);
        border: 1vw;
        border-radius: 1vw;
        margin-left: 35vw;
        margin-right: 35vw;
    }
}

/* Media for a Phone */
@media (max-width: 576px) {
    body {
        background: linear-gradient(white 5%,#E9B9FF 5% 40%, 
        #CB8BE5 40% 62%, #8537A5 62% 75%, 
        #690990 75% 98%, #222222 98% 100%) no-repeat center center fixed;
    }

    main{
        height: 100vh;
        text-align: center;
        display: block;
    }

    main h1{
        font-weight: 500;
        text-decoration: underline;
        font-size: 2.8em;
        margin-top: 0;
        width: 90vw;
        margin-left: 10%;
    }

    .socials {
        list-style-type: none;
        font-size: 2.4em;
        position: relative;
        padding-inline-start: 0px;
        margin-top: 109px;
    }

    .socials li {
        position: static;
        width: auto;
        z-index: 0;
    }

    .sub-social img {
        transition: all 0.05s linear;
        height: 7vh;
        border: 1px;
        border-radius: 15px;
        z-index: 2;
        position: relative;
    }

    .frosted {
        background-color: rgba(255, 255, 255, .25);
        backdrop-filter: blur(25px);
        border: 1vw;
        border-radius: 1vw;
        margin-left: 15%;
        margin-right: 5%;
    }
}