
/* Media for a PC */
@media (min-width: 576px) {
    #portbody {
        background: linear-gradient(to bottom left, white 8%, #E9B9FF 8% 18%, 
        #CB8BE5 18% 25%, #8537A5 25% 74%, 
        #690990 74% 93%, #222222 93% 100%) 
        no-repeat center center fixed;
        height: auto;
    }

    .top-bar h1 {
        text-align: center;
        font-size: 10ch;
        color: white;
        padding-top: 5%;
        margin-bottom: 50%;
    }

    #portmain p {
        text-align: center;
        max-width: 75%;
        margin-left: 15%;
        margin-right: 15%;
    }

    .top-bar {
        height: 59ch;
        padding: 0;
        margin: 0;
        margin-top: -10%;
        background-position: center;
        background-repeat: none;
        background-size: cover;
    }

    .pc-view {
        font-size: 7ch;
    }

    .mob-view {
        display: none;
    }
    
    .carousel-inner {
        height: 50vh;
        width: 50vw;
        text-align: center;
    }

    .carousel-inner p, .carousel-inner h5{
        color: white;
        background-color: rgba(0, 0, 0, 0.5);
        border: 5px;
        border-radius: 15px;
    }

    .carousel-inner p {
        font-size: .5em;
    }

    .carousel-inner h5{
        font-size: 1em;
    }

    .carousel {
        margin-left: 25%;
        margin-right: 25%;
    }

    .carousel-indicators {
        background-color: #222222;
    }

    .carousel-indicators .active {
        background-color: #690990;
    }

    .carousel-control-prev, .carousel-control-next {
        background-color: rgba(0, 0, 0, .5);
        height: 20%;
        width: 10%;
        margin-top: auto;
        margin-bottom: auto;
        border: 5px;
        border-radius: 50%;
        opacity: 1;
    }
}

/* Media for a Phone */
@media (max-width: 576px) {
    body {
        background: linear-gradient(white 5%,#E9B9FF 5% 40%, 
        #CB8BE5 40% 62%, #8537A5 62% 75%, 
        #690990 75% 98%, #222222 98% 100%) no-repeat center center fixed;
    }

    main {
        height: 100vh;
    }

    .top-bar h1 {
        text-align: center;
        font-size: 10ch;
        color: black;
        width: 90vw;
        margin-left: auto;
    }

    main p {
        text-align: center;
        max-width: 75%;
        margin-left: 15%;
        margin-right: 15%;
    }

    .top-bar {
        padding: 0;
        margin: 0;
        background-position: center;
        background-repeat: none;
        background-size: cover;
    }

    .pc-view {
        display: none;
    }
    
    .mob-view{
        background-color: rgba(0, 0, 0, .5);
        backdrop-filter: blur(50px);
        margin-left: 15%;
        margin-right: 5%;
        padding: 0;
        border-radius: 10%;
    }

    .websites {
        /* border-radius: 5px; */
        text-align: center;
        margin: 0;
        border-top: #E9B9FF 5px solid;
    }

    .websites a, .websites a:visited {
        color: white;
        text-decoration: none;
    }

    .context {
        height: fit-content;
        margin: 0;
        color: #CB8BE5;
    }
}